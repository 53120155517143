// Imports

import React from "react"

import teamMemberStyles from "./item-team-member.module.css"

// Header

const TeamMember = ({ teamMember }) => {
  const member = teamMember.childMarkdownRemark.frontmatter
  return (
    <div className={teamMemberStyles.container}>
      <img className={teamMemberStyles.img} src={member.image} alt="Team Member" />
      <div className={teamMemberStyles.text__container}>
        <h3 className={teamMemberStyles.title}>{member.name}</h3>
        <p className={teamMemberStyles.position}>{member.title}</p>
      </div>
      <div className={teamMemberStyles.email__container}>
        <a href={'mailto:' + member.email}>
          <p className={teamMemberStyles.email}>{member.email}</p>
        </a>
      </div>
    </div>
  )
}

export default TeamMember
