// Imports

import { useStaticQuery, graphql } from "gatsby"

// Hook

export const TeamMembersHook = () => {
  const query = useStaticQuery(
    graphql`
      query teamMembersQuery {
        allFile(filter: {sourceInstanceName: {eq: "content"}, relativeDirectory: {eq: "teamMembers"}}) {
        edges {
          node {
            id
            childMarkdownRemark {
              frontmatter {
                name
                title
                department
                email
                image
              }
            }
          }
        }
      }
    }
    `
  )
  return query.allFile.edges
}