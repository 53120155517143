// Imports

import React from "react"

import PropTypes from "prop-types"
import sideBySideStyles from "./side-by-side.module.css"

// Quote

const SideBySide = ({ imageHorizOrient, imgSrc, textVertOrient, titleAndDetails }) => {
    var detailsClassName = sideBySideStyles.details
    if (textVertOrient === 'center') {
      detailsClassName = sideBySideStyles.detailsCenter
    } 
    var imgContainerClassName = sideBySideStyles.img__containerLeft
    var textContainerClassName = sideBySideStyles.detailRight
    if (imageHorizOrient === 'right') {
      imgContainerClassName = sideBySideStyles.img__containerRight
      textContainerClassName = sideBySideStyles.detail
    }  
    return (
      <>
        <div className={sideBySideStyles.container}>
          <div className={sideBySideStyles.widthContainer}>
            <div className={imgContainerClassName}>
              <img src={imgSrc} alt="Side By Side" className={sideBySideStyles.img} />
            </div>
            <div className={detailsClassName}>
              {titleAndDetails.map((detail, index) => {
                return (
                  <div className={textContainerClassName}>
                    <h3 className={sideBySideStyles.title}>{detail.title}</h3>
                    <p className={sideBySideStyles.paragraph}>{detail.text}</p>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </>
    )
}

SideBySide.propTypes = {
  imageHorizOrient: PropTypes.string,
  imgSrc: PropTypes.string,
  textVertOrient: PropTypes.string,
  titleAndDetails: PropTypes.array,
}

SideBySide.defaultProps = {
  imageHorizOrient: "left",
  imgSrc: "",
  textVertOrient: "flex-start",
  titleAndDetails: [],
}

export default SideBySide
