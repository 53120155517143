// Imports

import React from "react"
import { Tab } from "semantic-ui-react"

import staffStyles from "./staff.module.css"
import { TeamMembersHook } from "../../hooks/team-members"
import TeamMember from "../../components/item-team-member/item-team-member"

// Header

const panes = () => {
  
  const members = TeamMembersHook()
  const board = members.filter(member => member.node.childMarkdownRemark.frontmatter.department === 'Board')
  const executive = members.filter(member => member.node.childMarkdownRemark.frontmatter.department === 'Executive Team')
  const managers = members.filter(member => member.node.childMarkdownRemark.frontmatter.department === 'Managers')
  
  return [
    {
      menuItem: 'Board',
      render: () => {
        return (
          <Tab.Pane attached={false}>
            <div className={staffStyles.container}>
              {board.map(member => {
                return <TeamMember teamMember={member.node} tag={member.node.id} />
              })}
            </div>
          </Tab.Pane>
        )
      },
    },
    {
      menuItem: 'Executive Team',
      render: () => {
        return (
          <Tab.Pane attached={false}>
            <div className={staffStyles.container}>
              {executive.map(member => {
                return <TeamMember teamMember={member.node} tag={member.node.id} />
              })}
            </div>
          </Tab.Pane>
        )
      },
    }
  ]
}

const Staff = ({ data }) => {
  return (
    <>
      <Tab menu={{ secondary: true, pointing: true }} panes={panes()} />
    </>
  )                                                                          
}

export default Staff
