// Imports

import React from "react"
import { graphql } from 'gatsby'

import SEO from "../components/seo"
import Layout from "../components/layout"
import Staff from "../components/staff/staff"
import aboutPageStyles from "../styles/pages/about.module.css"
import SideBySide from "../components/side-by-side/side-by-side"

// About

const AboutPage = ({ data }) => {
  const pageData = data.allFile.edges[0].node.childMarkdownRemark.frontmatter
  return (
    <Layout>
      <SEO title="ACE | Nevada's Leading Cannabis Testing Lab" />
      <div className={aboutPageStyles.hero}>
        <div className={aboutPageStyles.callToAction}>
          <h3 className={aboutPageStyles.title}>{pageData.heroTitle}</h3>
          <p className={aboutPageStyles.details}>{pageData.heroSubtitle}</p>
        </div>
      </div>
      <div className={aboutPageStyles.img__container}>
        <img src={pageData.heroImage} alt="Hero" className={aboutPageStyles.img}/>
      </div>
      <div className={aboutPageStyles.side_by_sides}>
        <SideBySide imageHorizOrient={"left"} imgSrc={pageData.firstSectionImage} textVertOrient="center" titleAndDetails={[{title: pageData.firstSectionTitle, text: pageData.firstSectionDetail}]} />
        <SideBySide imageHorizOrient={"right"} imgSrc={pageData.secondSectionImage} textVertOrient="center" titleAndDetails={[{title: pageData.secondSectionTitle, text: pageData.secondSectionDetail}]} />
      </div>
      <div className={aboutPageStyles.staff__container}>
        <Staff />
      </div>
    </Layout>
  )
}

export default AboutPage

export const query = graphql`
  query {
    allFile (filter: {sourceInstanceName: {eq: "content"} name: {eq: "about-page"}}) {
      edges {
        node {
          childMarkdownRemark {
            frontmatter {
              heroTitle
              heroSubtitle
              heroImage
              firstSectionTitle
              firstSectionDetail
              firstSectionImage
              secondSectionTitle
              secondSectionDetail
              secondSectionImage
          }
        }
      }
    }
  }
}`

